import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { noop } from 'lodash';
import { TrackingHandler } from 'client/tracking/handler';
import { HomepageAdExtensionMap } from 'client/tracking/maps/common/homepage-ad-extension';
import { CmsEntities } from 'client/data/models/cms';
import { isRenderedAdUnit } from 'client/utils/ads';
import { ErrorBoundary } from 'site-modules/shared/components/error-boundary/error-boundary';
import { VehicleShowcase } from 'site-modules/shared/components/upper-funnel/vehicle-showcase/vehicle-showcase';
import { ConquestSpotlightNativeAd } from 'site-modules/shared/components/native-ad/conquest-spotlight/conquest-spotlight-native-ad';
import { NativeAd } from 'site-modules/shared/components/ad-unit/native-ad';
import { NativeVideoAd } from 'site-modules/shared/components/native-ad/video/video-native-ad';
import { MrectNative } from 'site-modules/shared/components/native-ad/mrect/mrect-native';

const AD_TYPE = {
  MRECT: 'box-ad',
  MEDIUM_RECTANGLE: 'medium-rectangle',
  HIGH_IMPACT: 'high-impact',
  SPOTLIGHT: 'spotlight',
  VIDEO: 'video-ad',
};

const AD_TYPE_FLOAT_STYLES = {
  [AD_TYPE.VIDEO]: { minWidth: '327px' },
};

export function AdEntry({ entry, isMobile, linkWidgetContent, className, toggleForceNoSeparator, style }) {
  useEffect(() => {
    TrackingHandler.tryAddMap(HomepageAdExtensionMap);
  }, []);

  const hideEmptyAd = useCallback(slot => toggleForceNoSeparator(!isRenderedAdUnit(slot)), [toggleForceNoSeparator]);

  const onlyWired = entry.metadata('onlyWired').boolean();
  const onlyMobile = entry.metadata('onlyMobile').boolean();

  if ((onlyWired && isMobile) || (onlyMobile && !isMobile)) {
    return null;
  }

  const adType = entry.metadata('adType').value();
  const position = entry.metadata('pos').value();
  const float = entry.metadata('float').value();
  const renderWhenViewable = entry.metadata('renderWhenViewable').boolean();
  const verticalOffset = entry.metadata('verticalOffset').value('100%');
  const spaceAround = entry.metadata('spaceAround').boolean();
  const verticalAlign = entry.metadata('verticalAlign').value() || undefined;
  let renderAd;

  switch (adType || entry.id) {
    case AD_TYPE.MRECT:
    case AD_TYPE.MEDIUM_RECTANGLE: {
      const wiredBreakpoints = entry.metadata('wiredBreakpoints').json({ md: true, lg: true, xl: true });
      renderAd = (
        <MrectNative
          wrapperClass={classnames('mb-0_75', { 'hidden-lg-down': !wiredBreakpoints.md })}
          position={position}
          refreshable={false}
          wiredOnly={!isMobile}
          mobileOnly={isMobile}
          wiredBreakpoints={wiredBreakpoints}
          slotRenderEndListener={hideEmptyAd}
          useMinHeight
          renderWhenViewable={renderWhenViewable || isMobile}
          verticalOffset={verticalOffset}
          verticalAlign={verticalAlign}
        />
      );
      break;
    }
    case AD_TYPE.HIGH_IMPACT: {
      renderAd = linkWidgetContent ? (
        <ErrorBoundary>
          <VehicleShowcase content={linkWidgetContent.child('home-vehicle-showcase-v2')} bottomBorder={false} />
        </ErrorBoundary>
      ) : (
        <NativeAd
          adNameWired="edhighimpact"
          adNameMobile="medhighimpact"
          nativeStyle="none"
          position={position}
          all
          renderWhenViewable={false}
          refreshable={false}
          slotRenderEndListener={hideEmptyAd}
          useMinHeight
        />
      );
      break;
    }
    case AD_TYPE.SPOTLIGHT: {
      renderAd = (
        <ConquestSpotlightNativeAd
          position={position}
          refreshable={false}
          mobile={isMobile}
          slotRenderEndListener={hideEmptyAd}
          useMinHeight
          isEditorial
          renderWhenViewable={isMobile}
          verticalOffset="100%"
        />
      );
      break;
    }
    case AD_TYPE.VIDEO: {
      renderAd = (
        <NativeVideoAd
          slotRenderEndListener={hideEmptyAd}
          useMinHeight
          style={!isMobile && float ? AD_TYPE_FLOAT_STYLES[AD_TYPE.VIDEO] : {}}
        />
      );
      break;
    }
    default: {
      renderAd = null;
    }
  }

  return className || float || spaceAround ? (
    <div
      className={classnames(className, {
        'd-inline-block mx-2': !isMobile && float,
        'h-100 d-flex flex-column justify-content-around': spaceAround,
      })}
      style={!isMobile && float ? { float } : style}
    >
      {renderAd}
    </div>
  ) : (
    renderAd
  );
}

AdEntry.propTypes = {
  entry: CmsEntities.Content.isRequired,
  linkWidgetContent: CmsEntities.Content,
  toggleForceNoSeparator: PropTypes.func,
  isMobile: PropTypes.bool,
  className: PropTypes.string,
  style: PropTypes.shape({}),
};

AdEntry.defaultProps = {
  className: null,
  toggleForceNoSeparator: noop,
  linkWidgetContent: null,
  isMobile: false,
  style: {},
};
